/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { SocialCapitalRanking } from './SocialCapitalRanking';
import {
    SocialCapitalRankingFromJSON,
    SocialCapitalRankingFromJSONTyped,
    SocialCapitalRankingToJSON,
} from './SocialCapitalRanking';
import type { SocialCapitalRegion } from './SocialCapitalRegion';
import {
    SocialCapitalRegionFromJSON,
    SocialCapitalRegionFromJSONTyped,
    SocialCapitalRegionToJSON,
} from './SocialCapitalRegion';

/**
 * 
 * @export
 * @interface SocialCapitalTargetRegionSection
 */
export interface SocialCapitalTargetRegionSection {
    /**
     * 
     * @type {string}
     * @memberof SocialCapitalTargetRegionSection
     */
    targetUri: string;
    /**
     * 
     * @type {SocialCapitalRegion}
     * @memberof SocialCapitalTargetRegionSection
     */
    targetRegion: SocialCapitalRegion;
    /**
     * 
     * @type {SocialCapitalRanking}
     * @memberof SocialCapitalTargetRegionSection
     */
    ranking?: SocialCapitalRanking;
}

/**
 * Check if a given object implements the SocialCapitalTargetRegionSection interface.
 */
export function instanceOfSocialCapitalTargetRegionSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "targetUri" in value;
    isInstance = isInstance && "targetRegion" in value;

    return isInstance;
}

export function SocialCapitalTargetRegionSectionFromJSON(json: any): SocialCapitalTargetRegionSection {
    return SocialCapitalTargetRegionSectionFromJSONTyped(json, false);
}

export function SocialCapitalTargetRegionSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialCapitalTargetRegionSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetUri': json['target_uri'],
        'targetRegion': SocialCapitalRegionFromJSON(json['target_region']),
        'ranking': !exists(json, 'ranking') ? undefined : SocialCapitalRankingFromJSON(json['ranking']),
    };
}

export function SocialCapitalTargetRegionSectionToJSON(value?: SocialCapitalTargetRegionSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target_uri': value.targetUri,
        'target_region': SocialCapitalRegionToJSON(value.targetRegion),
        'ranking': SocialCapitalRankingToJSON(value.ranking),
    };
}

