/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
 */
export interface NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking {
    /**
     * 
     * @type {string}
     * @memberof NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
     */
    localProfileId: string;
    /**
     * 
     * @type {number}
     * @memberof NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
     */
    rank: number;
    /**
     * 
     * @type {string}
     * @memberof NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
     */
    localProfileName: string;
    /**
     * 
     * @type {string}
     * @memberof NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
     */
    localProfileImageUrl: string;
    /**
     * 
     * @type {number}
     * @memberof NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
     */
    yearlyAverageCheckinCount: number;
    /**
     * 
     * @type {string}
     * @memberof NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
     */
    reviewContent: string;
    /**
     * 
     * @type {string}
     * @memberof NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
     */
    localProfileTargetUri: string;
    /**
     * 
     * @type {string}
     * @memberof NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking
     */
    reviewTargetUri: string;
}

/**
 * Check if a given object implements the NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking interface.
 */
export function instanceOfNativeRecommendationLocalProfilesRankingSectionLocalProfileRanking(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "localProfileId" in value;
    isInstance = isInstance && "rank" in value;
    isInstance = isInstance && "localProfileName" in value;
    isInstance = isInstance && "localProfileImageUrl" in value;
    isInstance = isInstance && "yearlyAverageCheckinCount" in value;
    isInstance = isInstance && "reviewContent" in value;
    isInstance = isInstance && "localProfileTargetUri" in value;
    isInstance = isInstance && "reviewTargetUri" in value;

    return isInstance;
}

export function NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingFromJSON(json: any): NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking {
    return NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingFromJSONTyped(json, false);
}

export function NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localProfileId': json['local_profile_id'],
        'rank': json['rank'],
        'localProfileName': json['local_profile_name'],
        'localProfileImageUrl': json['local_profile_image_url'],
        'yearlyAverageCheckinCount': json['yearly_average_checkin_count'],
        'reviewContent': json['review_content'],
        'localProfileTargetUri': json['local_profile_target_uri'],
        'reviewTargetUri': json['review_target_uri'],
    };
}

export function NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingToJSON(value?: NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'local_profile_id': value.localProfileId,
        'rank': value.rank,
        'local_profile_name': value.localProfileName,
        'local_profile_image_url': value.localProfileImageUrl,
        'yearly_average_checkin_count': value.yearlyAverageCheckinCount,
        'review_content': value.reviewContent,
        'local_profile_target_uri': value.localProfileTargetUri,
        'review_target_uri': value.reviewTargetUri,
    };
}

