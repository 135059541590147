import { type StackflowReactPlugin } from '@stackflow/react'
import { type ReactNode } from 'react'

import { extractAppBuildType } from '@src/ts-utils/userAgent'

type DevToolsRootProps = {
  children: ReactNode
}

const DevToolsRoot = (props: DevToolsRootProps) => {
  const isDebugApp = extractAppBuildType(window.navigator.userAgent) === 'debug'

  return <>{props.children}</>
}

export function devtoolsPlugin(): StackflowReactPlugin {
  return () => ({
    key: 'devtoolsPlugin',
    wrapStack({ stack }) {
      return <DevToolsRoot>{stack.render()}</DevToolsRoot>
    },
  })
}
