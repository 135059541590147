import { z } from 'zod'

import { LocalStepKeys } from './type'

export const SearchQueryFrom = [
  'recent',
  'category_suggestion',
  'suggested',
  'recommendation_chip',
  'typed',
  'launchpad',
  'bottom_sheet_keyword',
  'unknown',
] as const

export const CommonParamsSchema = z.object({
  prevStep: z.enum(LocalStepKeys).nullable().optional(),
})

export const HomeStepParamsSchema = z
  .object({
    selectedPin: z.string().nullable().default(null),
  })
  .merge(CommonParamsSchema)

export const SearchTypingStepParamsSchema = z
  .object({
    query: z.string().nullable().default(null),
  })
  .merge(CommonParamsSchema)

export const SearchStepParamsSchema = z
  .object({
    query: z.string().nullable().default(null),
    selectedPin: z.string().nullable().default(null),
    queryFrom: z.enum(SearchQueryFrom).default('unknown'),
  })
  .merge(CommonParamsSchema)

export const PreviewStepParamsSchema = z
  .object({
    localProfileId: z.string(),
  })
  .merge(CommonParamsSchema)

export const SearchedPreviewStepParamsSchema = SearchStepParamsSchema.merge(
  PreviewStepParamsSchema
)

export type HomeStepParams = z.infer<typeof HomeStepParamsSchema>

export type SearchTypingStepParams = z.infer<
  typeof SearchTypingStepParamsSchema
>

export type SearchStepParams = z.infer<typeof SearchStepParamsSchema>

export type PreviewStepParams = z.infer<typeof PreviewStepParamsSchema>

export type SearchedPreviewStepParams = z.infer<
  typeof SearchedPreviewStepParamsSchema
>
